import React from "react";
import istanbulImage from "../assets/images/istanbul-image.png"; // Import the image
import AppShowcaseCard from "../components/common/AppShowcaseCard";
import istanbulLogo from "../assets/icons-ma/istanbul-icon.png"; // Import the image
import { AppInfo } from "../constants/appInfo";

const Istanbul = () => {
  const containerStyle = {
    position: "relative",
    height: "100vh", // Full viewport height
    width: "100vw", // Full viewport width
    margin: 0,
    padding: 0,
    display: "flex",
    justifyContent: "center", // Center horizontally
    alignItems: "center", // Center vertically
    overflow: "hidden",
  };

  const backgroundStyle = {
    position: "absolute",
    top: 0,
    left: 0,
    height: "100%",
    width: "100%",
    backgroundImage: `url(${istanbulImage})`, // Use the imported image
    backgroundSize: "cover", // Cover entire container
    backgroundPosition: "center", // Center the image
    backgroundRepeat: "no-repeat",
    filter: "blur(4px)", // Blur effect
    zIndex: -1, // Place the background behind the content
  };

  const contentStyle = {
    position: "relative", // Ensure the card stays above the blurred background
    zIndex: 1,
  };

  return (
    <div style={containerStyle}>
      {/* Background with blur */}
      <div style={backgroundStyle}></div>

      {/* Centered Card Content */}
      <div style={contentStyle}>
        <AppShowcaseCard
          cityName="Istanbul"
          description="Museum Discovery in Istanbul"
          appIcon={istanbulLogo}
          playStoreLink={AppInfo.ISMAndroidAppLink}
          appStoreLink={AppInfo.ISMiOSAppLink}
        />
      </div>
    </div>
  );
};

export default Istanbul;
