import React, { useState } from "react";
import "../screens/styles/ContactForm.css";
import Image from "../components/elements/Image";
import mailtoLink from "mailto-link";

const Support = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const link = mailtoLink({
      to: "museumsaround@gmail.com",
      subject: "Support",
      body: `Name: ${formData.name}\nEmail: ${formData.email}\nMessage: ${formData.message}`,
    });

    window.location.href = link;
  };

  return (
    <form onSubmit={handleSubmit}>
      <Image
        src={require("./../assets/icons-ma/ic_museumsaroundwhitelogo.png")}
        alt="Features split 03"
        width={528}
        height={396}
      />
      <h2>How can we help? 🙂</h2>
      <div>
        <label htmlFor="name">Name:</label>
        <input
          type="text"
          id="name"
          name="name"
          value={formData.name}
          onChange={handleChange}
          required
        />
      </div>

      <div>
        <label htmlFor="email">Email:</label>
        <input
          type="email"
          id="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          required
        />
      </div>

      <div>
        <label htmlFor="message">Message:</label>
        <textarea
          id="message"
          name="message"
          value={formData.message}
          onChange={handleChange}
          required
        />
      </div>

      <button type="submit">Submit</button>
    </form>
  );
};

export default Support;
