import React from "react";
import { Card, CardMedia, Typography, Box } from "@mui/material";
import playStore from "../../assets/images/image_playstore.png";
import appStore from "../../assets/images/image_appstore.png";

// Style Object
const styles = {
  card: {
    display: "flex",
    flexDirection: "column",
    borderRadius: "16px",
    boxShadow: 3,
    padding: "20px 32px",
    backgroundColor: "rgba(255, 255, 255, 0.5)", // White with 80% opacity
  },
  topSection: {
    display: "flex",
    alignItems: "center",
  },
  appIcon: {
    width: 80,
    height: 80,
    borderRadius: "16px",
    marginRight: "16px",
  },
  buttonsContainer: {
    display: "flex",
    justifyContent: "center",
    gap: "16px",
    marginTop: "16px",
  },
  storeButton: {
    height: "50px",
    width: "auto",
    cursor: "pointer",
  },
};

const AppShowcaseCard = ({
  cityName = "City Name",
  description = "App Description",
  appIcon,
  playStoreLink,
  appStoreLink,
}) => {
  return (
    <Card sx={styles.card}>
      {/* Top Section: Icon and Text Side-by-Side */}
      <Box sx={styles.topSection}>
        {/* App Icon */}
        <CardMedia
          component="img"
          image={appIcon}
          alt={`${cityName} Icon`}
          sx={styles.appIcon}
        />

        {/* App Title and Description */}
        <Box>
          <Typography variant="h5" component="div" sx={{ fontWeight: "bold" }}>
            {cityName}Museums
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {description}
          </Typography>
        </Box>
      </Box>

      {/* Bottom Section: Buttons */}
      <Box sx={styles.buttonsContainer}>
        <a href={playStoreLink} target="_blank" rel="noopener noreferrer">
          <img src={playStore} alt="Play Store" style={styles.storeButton} />
        </a>
        <a href={appStoreLink} target="_blank" rel="noopener noreferrer">
          <img src={appStore} alt="App Store" style={styles.storeButton} />
        </a>
      </Box>
    </Card>
  );
};

export default AppShowcaseCard;
