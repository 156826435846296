import React from "react";
import izmirImage from "../assets/images/izmir-image.png"; // Import the image
import AppShowcaseCard from "../components/common/AppShowcaseCard";
import izmirLogo from "../assets/icons-ma/izmir-icon.png"; // Import the image
import { AppInfo } from "../constants/appInfo";

const Izmir = () => {
  const containerStyle = {
    position: "relative",
    height: "100vh", // Full viewport height
    width: "100vw", // Full viewport width
    margin: 0,
    padding: 0,
    display: "flex",
    justifyContent: "center", // Center horizontally
    alignItems: "center", // Center vertically
    overflow: "hidden",
  };

  const backgroundStyle = {
    position: "absolute",
    top: 0,
    left: 0,
    height: "100%",
    width: "100%",
    backgroundImage: `url(${izmirImage})`, // Use the imported image
    backgroundSize: "cover", // Cover entire container
    backgroundPosition: "center", // Center the image
    backgroundRepeat: "no-repeat",
    filter: "blur(4px)", // Blur effect
    zIndex: -1, // Place the background behind the content
  };

  const contentStyle = {
    position: "relative", // Ensure the card stays above the blurred background
    zIndex: 1,
  };

  return (
    <div style={containerStyle}>
      {/* Background with blur */}
      <div style={backgroundStyle}></div>

      {/* Centered Card Content */}
      <div style={contentStyle}>
        <AppShowcaseCard
          cityName="Izmir"
          description="Museum Discovery in Izmir"
          appIcon={izmirLogo}
          playStoreLink={AppInfo.IZMAndroidAppLink}
          appStoreLink={AppInfo.IZMiOSAppLink}
        />
      </div>
    </div>
  );
};

export default Izmir;
