import React, { useState } from "react";
import classNames from "classnames";
import { SectionProps } from "../../utils/SectionProps";
import iconUrl from "../../assets/icons-ma/ic_museumsaroundwhitelogo.png";
import Image from "../elements/Image";
import AppleStoreButton from "../buttons/AppleStoreButton";
import PlayStoreButton from "../buttons/PlayStoreButton";
import { IconButton } from "@mui/material";

const propTypes = {
  ...SectionProps.types,
};

const defaultProps = {
  ...SectionProps.defaults,
};

const Hero = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
}) => {
  const outerClasses = classNames(
    "hero section center-content",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <section {...props} className={outerClasses}>
        <div className="container-sm">
          <div className="hero-content">
            <img
              src={iconUrl}
              style={{ height: "100%", width: 300, marginTop: 80 }}
            />
            <h1
              style={{ fontSize: 30 }}
              className="mt-5 mb-25 reveal-from-bottom"
              data-reveal-delay="200"
            >
              Unlock the World’s Best Museums{" "}
              <span className="text-color-primary">🏛</span>
            </h1>
            <div className="container-sm hero-content-wrapper">
              <p
                className="m-0 mb-32 reveal-from-bottom align-self-center text-start"
                data-reveal-delay="400"
              >
                <span className="text-large">
                  Explore the World of Art, Archaeology, Cultural Heritage,
                  Science, and Technology with a Museum App!
                </span>
              </p>

              <Image
                src={require("./../../assets/images/doublevertical.png")}
                className=""
              />
            </div>
          </div>
          <div>
            <IconButton
              onClick={() =>
                window.open(
                  "https://play.google.com/store/apps/details?id=com.lifeyapps.museumsaround"
                )
              }
              color="primary"
              aria-label="upload picture"
              component="label"
            >
              <PlayStoreButton />
            </IconButton>
            <IconButton
              onClick={() =>
                window.open(
                  "https://apps.apple.com/us/app/museumsaround/id6447110231"
                )
              }
              color="primary"
              aria-label="upload picture"
              component="label"
            >
              <AppleStoreButton />
            </IconButton>
          </div>
        </div>
      </section>
    </>
  );
};

Hero.propTypes = propTypes;
Hero.defaultProps = defaultProps;

export default Hero;
