export const AppInfo = {
  MAprivacyPolicyURL:
    "https://tall-hamburger-9c1.notion.site/MuseumsAround-Privacy-Policy-f1771dcd853d4418a25af1abd83a65b3",
  MAtermsConditionsURL:
    "https://tall-hamburger-9c1.notion.site/MuseumsAround-Terms-and-Conditions-15a72a685d858010b5f9fe15893c81b5?pvs=74",
  IZMiOSAppLink: "https://apps.apple.com/us/app/izmirmuseums/id6739137784",
  ISMiOSAppLink: "https://apps.apple.com/us/app/istanbulmuseums/id6739504183",
  IZMAndroidAppLink: "https://play.google.com/store/apps/details?id=com.lifeyapps.izmirmuseums",
  ISMAndroidAppLink: "https://play.google.com/store/apps/details?id=com.lifeyapps.istanbulmuseums",
  IZMprivacyPolicyURL:
    "https://tall-hamburger-9c1.notion.site/IzmirMuseums-Privacy-Policy-15e72a685d858053945bfac112d28b06",
  IZMtermsConditionsURL:
    "https://tall-hamburger-9c1.notion.site/IzmirMuseums-Terms-and-Conditions-15e72a685d85807fa4c8e8a63da35f18",
  ISMprivacyPolicyURL:
    "https://tall-hamburger-9c1.notion.site/IstanbulMuseums-Privacy-Policy-15e72a685d8580a1ae37c061d8c09bc4",
  ISMtermsConditionsURL:
    "https://tall-hamburger-9c1.notion.site/IstanbulMuseums-Terms-and-Conditions-15e72a685d85802f9ae0ff71c443f8fc",
};
